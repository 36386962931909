"use client"

import { Fragment } from "react"
import { clsx } from "clsx"

import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import type { TShopifyVideo } from "~/lib/storyblok/schemas/library/components/shopify_video"
import { FillImage } from "~/components/ui/FillImage"
import { Link } from "~/components/ui/Link"
import MediaShopTheLookCtaClient from "~/components/ui/MediaShopTheLookItem/components/MediaShopTheLookCtaClient"
import { type ShopTheLookPanelProps } from "~/components/ui/Panels/ShopTheLook"
import Video from "~/components/ui/Video"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { showFrom } from "~/styles/utils/show-hide"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

export type TDiptychV2Item = {
  assets: (TShopifyVideo | TImageDesktopMobileProps)[]
  title: string | null
  titleUnderline: boolean
  overlay: boolean
  cta: any
  withShopTheLookCta?: boolean
  shopTheLookGids?: ShopTheLookPanelProps["gids"]
}
export type TDiptychV2 = {
  items: TDiptychV2Item[]
  hasMargins?: boolean
}
export type DiptychV2Props = SliceWithProps<TDiptychV2>

function DiptychV2({ isFirstSlice, items, hasMargins }: DiptychV2Props) {
  if (!items) return null

  return (
    <div data-comp="Slices/DiptychV2" className={clsx(css.DiptychV2, { [css.hasMargin]: !isFirstSlice && hasMargins })}>
      {items.map(({ assets, title, titleUnderline, overlay, cta, withShopTheLookCta, shopTheLookGids }, index) => {
        const Wrapper = cta?.href ? Link : "div"
        return (
          <Wrapper key={`diptych-v2-${index}`} href={cta?.href} className={css.itemWrapper}>
            {assets.map((item, index) => {
              if (!item) return null
              if (item.component === "image_desktop_mobile") {
                return (
                  <Fragment key={`image-${index}`}>
                    {item.assetDesktop && (
                      <FillImage
                        className={clsx(css.item, showFrom("desktop", "block"), showFrom("mobile", "none"))}
                        {...item.assetDesktop}
                        sizesFromBreakpoints={SIZES}
                        priority={isFirstSlice}
                        asPlaceholder
                      />
                    )}
                    {item.assetMobile && (
                      <FillImage
                        className={clsx(css.item, showFrom("mobile", "block"), showFrom("desktop", "none"))}
                        {...item.assetMobile}
                        sizesFromBreakpoints={SIZES}
                        priority={isFirstSlice}
                        asPlaceholder
                      />
                    )}
                  </Fragment>
                )
              }
              if (item.component === "shopify_video") {
                return (
                  <Fragment key={`video-${index}`}>
                    {item.desktop && (
                      <Video
                        {...item.desktop}
                        className={clsx(
                          css.item,
                          sprinkles({ display: { mobile: "none", tablet: "block" }, height: "100%" })
                        )}
                        fill
                      />
                    )}
                    {item.mobile && (
                      <Video
                        {...item.mobile}
                        className={clsx(
                          css.item,
                          sprinkles({ display: { mobile: "block", tablet: "none" }, height: "100%" })
                        )}
                        fill
                      />
                    )}
                  </Fragment>
                )
              }
            })}

            <div className={clsx(css.content, overlay && css.overlay)}>
              {title && (
                <p className={clsx(css.title)} style={titleUnderline ? { textDecoration: "underline" } : undefined}>
                  {title}
                </p>
              )}

              <MediaShopTheLookCtaClient shouldEnable={withShopTheLookCta} gids={shopTheLookGids} />
            </div>
          </Wrapper>
        )
      })}
    </div>
  )
}

export default DiptychV2
