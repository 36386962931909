import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import type { TShopifyVideo } from "~/lib/storyblok/schemas/library/components/shopify_video"
import { FillImage } from "~/components/ui/FillImage"
import Video from "~/components/ui/Video"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 }, { ratio: 39 / 60 }]

export type TImageOrVideo = PropsWithClassName<{
  priority?: boolean
  background: TShopifyVideo | TImageDesktopMobileProps
  desktopRatio?: Sprinkles["ratio"]
  mobileRatio?: Sprinkles["ratio"]
}>

export default function ImageOrVideo({
  className,
  background,
  priority = false,
  desktopRatio,
  mobileRatio,
}: TImageOrVideo) {
  if (!background) return null

  if (background?.component === "image_desktop_mobile") {
    return (
      <div className={clsx(className, css.ImageOrVideo)}>
        {background?.assetDesktop && (
          <FillImage
            className={clsx(sprinkles({ display: { mobile: "none", desktop: "block" } }))}
            {...background.assetDesktop}
            sizesFromBreakpoints={SIZES}
            priority={priority}
            asPlaceholder
            ratio={desktopRatio}
          />
        )}
        {background?.assetMobile && (
          <FillImage
            className={clsx(sprinkles({ display: { mobile: "block", desktop: "none" } }))}
            {...background.assetMobile}
            sizesFromBreakpoints={SIZES}
            priority={priority}
            asPlaceholder
            ratio={mobileRatio}
          />
        )}
      </div>
    )
  }

  if (background?.component === "shopify_video") {
    return (
      <div className={clsx(className, css.ImageOrVideo)}>
        {background.desktop && (
          <Video {...background.desktop} className={sprinkles({ display: { mobile: "none", tablet: "block" } })} fill />
        )}
        {background.mobile && (
          <Video {...background.mobile} className={sprinkles({ display: { mobile: "block", tablet: "none" } })} fill />
        )}
      </div>
    )
  }
}
