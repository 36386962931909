"use client"

import { usePathname } from "next/navigation"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import useAuthentication from "~/hooks/account/useAuthentication"
import InlineCta from "~/components/ui/InlineCta"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import LoginPanel from "~/components/ui/Panels/Login"
import RichText from "~/components/ui/RichText"
import SquareCta from "~/components/ui/SquareCta"
import SquareCtaV2 from "~/components/ui/SquareCtaV2"
import { EmblaSlider } from "~/components/shared/EmblaSlider/index.client"
import HeroBackgroundV2, {
  type THeroBackgroundV2,
} from "~/components/slices/HeroV2/_components/HeroBackgroundV2/index.client"
import HeroTitleV2, { type THeroTitleV2 } from "~/components/slices/HeroV2/_components/HeroTitleV2/index.client"
import type { SliceWithProps } from "~/components/slices/SliceManager"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { usePanel } from "~/managers/PanelManager"

import * as css from "./styles.css"

export type TTitleRichtextProps = {
  fontStyleType: "h2" | "h3"
  text: SbRichtext
}

export type THeroV2Item = {
  background: THeroBackgroundV2["background"]
  contentPosition: "center" | "left" | "bottom"
  suptitle: SbRichtext | null
  description: SbRichtext | null
  title: THeroTitleV2["content"] | null
  ctas: TLinkLabel[]
  links: TLinkLabel[]
  withLogin?: boolean
  returnToCurrentPage?: boolean
  returnTo?: Nullish<string>
  color: "white" | "black"
}

export type THeroV2 = {
  isMargins: boolean
  items: THeroV2Item[]
}

export type HeroV2Props = SliceWithProps<THeroV2>

export function HeroV2Item({
  returnToCurrentPage,
  returnTo,
  color,
  contentPosition,
  suptitle,
  title,
  isMargins,
  isFirstSlice,
  description,
  ctas,
  links,
  withLogin,
  background,
}: THeroV2Item & Pick<HeroV2Props, "isFirstSlice" | "isMargins">) {
  const { add } = usePanel()
  const t = useTranslate()

  const pathname = usePathname()
  const { data: customer } = useAuthentication()
  const processedReturnTo = returnToCurrentPage ? pathname : returnTo || null

  return (
    <div className={clsx(css.HeroV2Item)}>
      <div className={css.content({ contentPosition })} style={{ color: color }}>
        {suptitle && <RichText className={css.suptitle({ contentPosition })} render={suptitle} disableStyles />}
        {title && <HeroTitleV2 content={title} priority={isFirstSlice} contentPosition={contentPosition} />}
        {description && (
          <RichText className={css.description({ contentPosition })} render={description} disableStyles />
        )}
        {ctas && (
          <div className={css.ctasLinks({ contentPosition })}>
            {ctas.map((cta, index) => (
              <SquareCtaV2
                key={`cta-${index}`}
                {...cta}
                size="small"
                theme={color === "white" ? "backgroundWhite" : "backgroundBlack"}
              />
            ))}
          </div>
        )}
        {links && (
          <div className={css.ctasLinks({ contentPosition })}>
            {links.map((link, index) => {
              return <InlineCta key={`link-${index}`} {...link} withDefaultLine size="small" color={color} />
            })}
          </div>
        )}
        {withLogin && !customer && (
          <div className={css.ctasLinks({ contentPosition })}>
            <SquareCta
              theme={color === "white" ? "backgroundWhite" : "backgroundBlack"}
              onClick={() => add(<LoginPanel {...(processedReturnTo ? { returnTo: processedReturnTo } : null)} />)}
              aria-label={t("account_log_in")}
            >
              {t("account_log_in")}
            </SquareCta>
          </div>
        )}
      </div>
      <HeroBackgroundV2 background={background} priority={isFirstSlice} isMargins={isMargins} />
    </div>
  )
}

export function HeroV2({
  isFirstSlice,

  isMargins,
  items,
}: HeroV2Props) {
  return (
    <section data-comp="Slices/HeroV2" className={clsx(css.HeroV2, { [css.isMargins as string]: isMargins })}>
      <EmblaSlider
        className={css.Slider}
        hideOverflow
        withStepper
        withAutoplay
        options={{
          align: "start",
          loop: true,
          active: items?.length > 1,
          watchDrag: items?.length > 1,
        }}
        autoPlayOptions={{ stopOnInteraction: false, delay: 5000 }}
      >
        {items.map((item, index) => (
          <HeroV2Item key={`hero_v2_item_${index}`} {...item} isFirstSlice={isFirstSlice} isMargins={isMargins} />
        ))}
      </EmblaSlider>
    </section>
  )
}

export default HeroV2
