import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { THeroDiptychImagesVideos } from "~/lib/storyblok/schemas/library/components/hero_diptych_images_videos"
import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import type { TShopifyVideo } from "~/lib/storyblok/schemas/library/components/shopify_video"
import { FillImage } from "~/components/ui/FillImage"
import HeroDiptychImagesVideosV2 from "~/components/ui/HeroDiptychImagesVideosV2/index.client"
import Video from "~/components/ui/Video"
import type { THeroV2 } from "~/components/slices/HeroV2/index.client"

import { sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

const SIZES = [{ ratio: 1 }]

export type THeroBackgroundV2 = PropsWithClassName<{
  background: (TShopifyVideo | THeroDiptychImagesVideos | TImageDesktopMobileProps)[]
  priority?: boolean
  isMargins: THeroV2["isMargins"]
}>

export default function HeroBackgroundV2({ className, background, isMargins, priority = false }: THeroBackgroundV2) {
  if (!background) return null
  return (
    <div className={clsx(className, css.HeroBackgroundV2)}>
      {background.map((item, index) => {
        if (!item) return null

        if (item.component === "image_desktop_mobile") {
          return (
            <div className={sprinkles({ width: "100%", height: "100%" })} key={`image-desktop-mobile-${index}`}>
              {item.assetDesktop && (
                <FillImage
                  className={clsx(sprinkles({ display: { mobile: "none", desktop: "block" } }))}
                  {...item.assetDesktop}
                  sizesFromBreakpoints={SIZES}
                  priority={priority}
                  asPlaceholder
                />
              )}

              {item.assetMobile && (
                <FillImage
                  className={clsx(sprinkles({ display: { mobile: "block", desktop: "none" } }))}
                  {...item.assetMobile}
                  sizesFromBreakpoints={SIZES}
                  priority={priority}
                  asPlaceholder
                />
              )}
            </div>
          )
        }

        if (item.component === "hero_diptych_images_videos") {
          if (!item?.items) return null
          return (
            <HeroDiptychImagesVideosV2
              key={`hero_diptych_images_videos-v2-${index}`}
              className={sprinkles({ width: "100%", height: "100%" })}
              background={item.items}
              priority={priority}
              isMargins={isMargins}
            />
          )
        }

        if (item.component === "shopify_video") {
          return (
            <div className={sprinkles({ width: "100%", height: "100%" })} key={`shopify-video-${index}`}>
              {item.desktop && (
                <Video
                  {...item.desktop}
                  className={sprinkles({ display: { mobile: "none", tablet: "block" }, height: "100%" })}
                  fill
                  withPlayPauseButton
                />
              )}
              {item.mobile && (
                <Video
                  {...item.mobile}
                  className={sprinkles({ display: { mobile: "block", tablet: "none" }, height: "100%" })}
                  fill
                  withPlayPauseButton
                />
              )}
            </div>
          )
        }
      })}
    </div>
  )
}
