"use client"

import { clsx } from "clsx"

import type { SbRichtext } from "~/lib/storyblok/schemas/default/richtext"
import InlineCta from "~/components/ui/InlineCta"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import RichText from "~/components/ui/RichText"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

export type TEndOfPageDispatchV2 = {
  title: SbRichtext | null
  links: TLinkLabel[]
}
export type EndOfPageDispatchV2Props = SliceWithProps<TEndOfPageDispatchV2>

function EndOfPageDispatchV2({ title, links }: EndOfPageDispatchV2Props) {
  return (
    <div data-comp="Slices/EndOfPageDispatchV2" className={css.EndOfPageDispatchV2}>
      <div className={css.content}>
        {title && <RichText className={css.title} render={title} disableStyles />}

        {links && (
          <div className={css.links}>
            {links.map((item, index) => {
              return <InlineCta key={`link_${index}`} {...item} className={clsx(css.link)} withLine withDefaultLine />
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default EndOfPageDispatchV2
