"use client"

import { Fragment } from "react"
import clsx from "clsx"

import type { TDiptychCampaignV2Item } from "~/lib/storyblok/schemas/library/components/diptych_campaign_v2_item"
import { FillImage } from "~/components/ui/FillImage"
import InlineCta from "~/components/ui/InlineCta"
import RichText from "~/components/ui/RichText"
import SquareCta from "~/components/ui/SquareCta"
import Video from "~/components/ui/Video"

import { sprinkles } from "~/styles/sprinkles.css"
import { showFrom } from "~/styles/utils/show-hide"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

export function DiptychCampaignV2({
  item,
  color: themeColor,
  isPriority,
}: {
  item: TDiptychCampaignV2Item
  color: "white" | "black"
  isPriority: boolean
}) {
  const { contentPosition, contentAlign, assets, title, description, ctaStyle, buttons } = item

  if (!assets?.length) return null

  return (
    <>
      {assets.map((asset, key) => (
        <Fragment key={key}>
          {asset.component === "image_desktop_mobile" && (
            <>
              {asset.assetDesktop && (
                <FillImage
                  className={clsx(css.image, showFrom("desktop", "block"), showFrom("mobile", "none"))}
                  {...asset.assetDesktop}
                  sizesFromBreakpoints={SIZES}
                  priority={isPriority}
                  asPlaceholder
                />
              )}
              {asset.assetMobile && (
                <FillImage
                  className={clsx(css.image, showFrom("mobile", "block"), showFrom("desktop", "none"))}
                  {...asset.assetMobile}
                  sizesFromBreakpoints={SIZES}
                  priority={isPriority}
                  asPlaceholder
                />
              )}
            </>
          )}
          {asset.component === "shopify_video" && (
            <>
              {asset.desktop && (
                <Video
                  {...asset.desktop}
                  className={sprinkles({ display: { mobile: "none", tablet: "block" }, height: "100%" })}
                  fill
                />
              )}
              {asset.mobile && (
                <Video
                  {...asset.mobile}
                  className={sprinkles({ display: { mobile: "block", tablet: "none" }, height: "100%" })}
                  fill
                />
              )}
            </>
          )}
        </Fragment>
      ))}
      <div
        className={css.wrapper}
        style={{
          ...(contentAlign === "left"
            ? { alignItems: "flex-start", textAlign: "left" }
            : { alignItems: "center", textAlign: "center" }),
          ...(contentPosition === "top"
            ? { justifyContent: "flex-start" }
            : contentPosition === "center"
            ? { justifyContent: "center" }
            : { justifyContent: "flex-end" }),
        }}
      >
        <div className={css.content}>
          {title && <RichText className={css.title} render={title} />}
          {description && <RichText className={css.description} render={description} />}
        </div>

        {buttons &&
          (ctaStyle === "inline"
            ? buttons.map((button, index) => {
                return (
                  <InlineCta
                    key={`button_${index}`}
                    {...button}
                    className={css.link}
                    withLine
                    withDefaultLine
                    color={themeColor}
                  />
                )
              })
            : buttons.map((button, index) => {
                return (
                  <SquareCta
                    {...button}
                    key={`button_${index}`}
                    theme={themeColor === "white" ? "backgroundWhite" : "backgroundBlack"}
                  />
                )
              }))}
      </div>
    </>
  )
}
