"use client"

import type { RecipeVariants } from "@vanilla-extract/recipes"
import clsx from "clsx"

import { Link, type LinkProps } from "~/components/ui/Link"
import Spinner from "~/components/ui/Spinner"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type SquareCtaVariants = RecipeVariants<typeof css.button>

export type SquareCtaV2Props = LinkProps & SquareCtaVariants & {}

function SquareCtaV2({
  className,
  children,
  isLoading = false,
  theme = "borderBlack",
  size = "medium",
  onClick,
  ...rest
}: SquareCtaV2Props) {
  return (
    <Link
      className={clsx(
        className,
        text,
        css.button({
          size,
          theme,
        })
      )}
      {...rest}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
    >
      {(props) => (
        <>
          {isLoading && <Spinner className={clsx(css.spinner)} />}
          <span className={clsx(css.inner({ hidden: isLoading }), sprinkles({ pointerEvents: "none" }))}>
            {typeof children === "function" ? children(props) : children}
          </span>
        </>
      )}
    </Link>
  )
}

export default SquareCtaV2
