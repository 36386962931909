"use client"

import { createRef, type CSSProperties, type MouseEvent } from "react"
import clsx from "clsx"
import { useHover } from "usehooks-ts"

import type { UnlikelyMoney } from "@unlikelystudio/commerce-connector"
import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { Nullish, PropsWithClassName } from "~/@types/generics"
import useLocale from "~/hooks/useLocale"
import BackInStockPopin from "~/components/ui/BackInStockPopin"
import type { TBackInStock } from "~/components/ui/BackInStockPopin/_data/serializer"
import CardTags from "~/components/ui/CardTags"
import { type ImageProps } from "~/components/ui/Image"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import { Link, type LinkProps } from "~/components/ui/Link"
import { serializeSfPrice } from "~/components/ui/Price/_data/serializer"
import QuickBuy, { type QuickBuyButton, type QuickBuyProps } from "~/components/ui/ProductCardSlider/Layout/QuickBuy"
import Slider, { type SliderProps } from "~/components/ui/ProductCardSlider/Layout/Slider"
import type { TVPPrice } from "~/components/ui/ProductHeader/_data/serialize-vp-price"
import SquareCta from "~/components/ui/SquareCta"
import type { TVideo } from "~/components/ui/Video"
import type { CartLineItemPayload } from "~/providers/GTMTrackingProvider/constants"
import { ClientTranslate } from "~/providers/I18nProvider/ClientTranslate"
import { useCollectionGridSize } from "~/managers/CollectionManager"
import { usePopin } from "~/managers/PopinManager"
import { arrayMove } from "~/utils/array-move"
import isTouchScreen from "~/utils/is-touch-screen"
import { processVpPrice } from "~/utils/vp-prices/process-vp-prices"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export const IMAGES_SIZES = {
  small: [{ breakpoint: breakpoints.md, ratio: 1 / 3 }, { ratio: 1 }],
  large: [{ breakpoint: breakpoints.md, ratio: 1 / 3 }, { ratio: 1 / 2 }],
}

export type LayoutProps = PropsWithClassName<{
  widthClassName?: string
  link: LinkProps
  slider: Omit<SliderProps, "medias"> & {
    images: TImage[]
    video?: Nullish<TVideo>
    videoPosition?: number
  }
  priority?: boolean
  stockAlertEnabled: boolean
  isOutOfStock: boolean
  quickBuy?: {
    handle: QuickBuyProps["handle"]
  }
  backInStock: Nullish<TBackInStock>
  quickBuyProps: QuickBuyButton[]
  style?: CSSProperties
  layout?: "grid" | "heightFluid" | undefined
  cardTags?: Nullish<string[]>
  collections: string[]
  trackingData: CartLineItemPayload
  title: string
  withImageHeight?: boolean
  price: UnlikelyMoney
  compareAtPrice: Nullish<UnlikelyMoney>
  vpPrice: Nullish<TVPPrice>
  enableDragOfProductCards?: boolean
  dragProps?: SliderProps["dragProps"]
  isGiftCard?: boolean
  ratio?: ImageProps["ratio"]
  sizes?: ImageProps["sizesFromBreakpoints"]
}>

function Layout({
  className,
  children,
  link,
  style,
  slider,
  layout,
  quickBuy,
  backInStock,
  stockAlertEnabled,
  isOutOfStock,
  widthClassName,
  priority = false,
  cardTags,
  title,
  price,
  compareAtPrice,
  vpPrice,
  withImageHeight = false,
  dragProps,
  isGiftCard,
  ratio,
  sizes,
}: LayoutProps) {
  const locale = useLocale()
  const { data: customer } = useGetCustomer()
  const [grid] = useCollectionGridSize()
  const computedWidthClassName = widthClassName ?? css.width({ grid: layout === "grid" ? grid : undefined })
  const ref = createRef<HTMLButtonElement | HTMLAnchorElement>()
  const isHover = useHover(ref)
  const { add: addPopin } = usePopin()
  const handleOpenBackInStock = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (backInStock && !isOutOfStock) {
      addPopin(<BackInStockPopin {...backInStock} />)
    }
  }

  // display quick buy if:
  // - quickBuy is defined
  // - product is not out of stock
  // - product is out of stock but stock alert is disabled
  const shouldDisplayQuickBuy = quickBuy && (!isOutOfStock || (isOutOfStock && !stockAlertEnabled && !isGiftCard))

  const { images, video, videoPosition = 1, ...sliderProps } = slider

  const medias = arrayMove(
    [{ type: "video" as const, data: video! }, ...images.map((image) => ({ type: "image" as const, data: image }))],
    0,
    videoPosition
  ).filter((media) => media.data)

  const { processedPrice, processedCompareAtPrice } = processVpPrice({
    price,
    compareAtPrice,
    vpPrice,
    customer,
  })

  const sfPrice = serializeSfPrice(locale, processedPrice, processedCompareAtPrice)

  return (
    <Link
      ref={ref}
      className={clsx(css.Layout({ heightFLuid: layout === "heightFluid" }), className, computedWidthClassName)}
      style={style}
      {...link}
    >
      <div className={clsx(css.header({ heightFLuid: layout === "heightFluid" }), sprinkles({ position: "relative" }))}>
        {cardTags?.length && <CardTags className={css.tags} items={cardTags?.slice(0, 2) ?? []} />}
        {!isGiftCard && stockAlertEnabled && isOutOfStock && (
          <div className={css.outOfStockLayer}>
            <SquareCta
              className={text({ design: "neue-10-12" })}
              overrideTextPresets
              theme="backgroundBlack"
              onClick={handleOpenBackInStock}
            >
              <ClientTranslate tKey="stock_alert" />
            </SquareCta>
          </div>
        )}
        <Slider
          // Re-mount the slider component on handle change.
          // This is needed to avoid a bug where the slider is not displayed when you choose other colors in the product card.
          key={quickBuy?.handle}
          className={clsx(slider.className, css.headerItem)}
          widthClassName={computedWidthClassName}
          sizes={sizes ? sizes : IMAGES_SIZES[grid]}
          outOfStock={!isGiftCard && isOutOfStock}
          fill={layout === "heightFluid"}
          medias={medias}
          priority={priority}
          {...sliderProps}
          withImageHeight={withImageHeight}
          dragProps={{ ...(dragProps ? dragProps : {}) }}
          ratio={ratio}
        />
        {shouldDisplayQuickBuy && (
          <QuickBuy
            className={clsx(css.headerItem, css.quickBuy({ visible: isHover && !isTouchScreen }))}
            image={images?.[1] ?? images?.[0]}
            price={sfPrice}
            title={title}
            isHover={isHover}
            {...quickBuy}
          />
        )}
      </div>
      {children}
    </Link>
  )
}

export default Layout
