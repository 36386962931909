import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import type { TShopifyVideo } from "~/lib/storyblok/schemas/library/components/shopify_video"
import ImageOrVideo from "~/components/ui/ImageOrVideo"
import type { THeroV2 } from "~/components/slices/HeroV2/index.client"

import * as css from "./styles.css"

type THeroDiptychImagesVideosV2 = PropsWithClassName<{
  background: (TShopifyVideo | TImageDesktopMobileProps)[]
  priority?: boolean
  isMargins: THeroV2["isMargins"]
}>

function HeroDiptychImagesVideosV2({ className, priority = false, background, isMargins }: THeroDiptychImagesVideosV2) {
  if (!background) return null

  return (
    <div className={clsx(className, css.HeroDiptychImagesVideosV2, { [css.isMargins as string]: isMargins })}>
      {background?.map((item, index) => {
        return (
          <ImageOrVideo
            className={clsx(css.item, { [css.secondItem as string]: index > 0 })}
            background={item}
            priority={priority}
            key={`image-or-video-hero-diptych-${index}`}
          />
        )
      })}
    </div>
  )
}

export default HeroDiptychImagesVideosV2
