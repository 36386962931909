"use client"

import clsx from "clsx"

import type { TDiptychLockupV2 } from "~/lib/storyblok/schemas/library/components/diptych_text_image_lockup_v2"
import InlineCta from "~/components/ui/InlineCta"
import RichText from "~/components/ui/RichText"

import * as css from "./styles.css"

export default function DiptychLockupV2(item: TDiptychLockupV2) {
  const { title, description, links } = item
  if (!title && !description && links.length === 0) return null

  return (
    <div className={css.wrapper}>
      <div className={css.content}>
        {title && <RichText className={clsx(css.title)} render={title} disableStyles />}
        {description && <RichText className={clsx(css.description)} render={description} disableStyles />}
      </div>
      {links &&
        links.map((item, index) => {
          return (
            <InlineCta
              key={`link_${index}`}
              {...item}
              className={clsx(css.link)}
              withLine
              withDefaultLine
              color="black"
            />
          )
        })}
    </div>
  )
}
