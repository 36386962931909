"use client"

import { useRef, type ComponentProps } from "react"
import { clsx } from "clsx"
import { useHover } from "usehooks-ts"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { useIntervalWithVisibilityCheck } from "~/hooks/useIntervalWithVisibilityCheck"
import ClientSliderNavigation from "~/components/slices/Diptich/ClientSliderNavigation"

import * as css from "./styles.css"

export type ClientSliderProps = PropsWithClassName<
  { children: ComponentProps<typeof Slider>["children"] } & ComponentProps<"div">
>

function ClientSlider({ className, children, ...rest }: ClientSliderProps) {
  const [{ maxSlideIndex, slideIndex, setSlideIndex, enableDrag, nextSlide }, setSliderState] = useSliderState()
  const containerRef = useRef<HTMLDivElement>(null)
  const isHover = useHover(containerRef)

  const { ref } = useIntervalWithVisibilityCheck(nextSlide, isHover ? null : 3000)

  return (
    <div ref={containerRef} className={clsx(css.ClientSlider, className)} {...rest}>
      <div className={clsx(css.marker)} ref={ref} />
      <Slider setSliderState={setSliderState} snap infinite>
        {children}
      </Slider>
      <ClientSliderNavigation
        className={clsx(css.navigation)}
        maxSlideIndex={maxSlideIndex}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        enableDrag={enableDrag}
      />
    </div>
  )
}

export default ClientSlider
