"use client"

import { clsx } from "clsx"

import type { Nullish } from "~/@types/generics"
import type { TDiptychCampaignV2Item } from "~/lib/storyblok/schemas/library/components/diptych_campaign_v2_item"
import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import type { TShopifyVideo } from "~/lib/storyblok/schemas/library/components/shopify_video"
import type { TImage } from "~/components/ui/Image/_data/serializer"
import type { TLink } from "~/components/ui/Link/_data/serializer"
import { DiptychCampaignV2 } from "~/components/slices/DiptychCampaignProductV2/DiptychCampaignV2"
import { DiptychProductV2 } from "~/components/slices/DiptychCampaignProductV2/DiptychProductV2"
import type { SliceWithProps } from "~/components/slices/SliceManager"

import * as css from "./styles.css"

export type TNewProductsArray = {
  component: string
  image: Nullish<TImage>
  asset: Nullish<TImageDesktopMobileProps | TShopifyVideo>
  price?: Nullish<string>
  link?: Nullish<TLink>
  title: Nullish<string>
}

export type TClientDiptychCampaignProductV2 = {
  color: "white" | "black"
  campaign: TDiptychCampaignV2Item[]
  products: any[]
  hasMargins?: boolean
}

export function ClientDiptychCampaignProductV2({
  className,
  isFirstSlice,
  color,
  campaign,
  products,
  hasMargins,
}: SliceWithProps<TClientDiptychCampaignProductV2>) {
  return (
    <div
      data-comp="Slices/DiptychCampaignProductV2Element"
      className={clsx(className, css.DiptychCampaignProductV2, { [css.hasMargin]: !isFirstSlice && hasMargins })}
      style={{
        ...(color === "white" ? { color: "white" } : { color: "black" }),
      }}
    >
      {campaign.map((item, index) => (
        <div className={css.itemContainer} key={`diptych-campaign-v2-${index}`}>
          <DiptychCampaignV2 item={item} color={color} isPriority={Boolean(isFirstSlice)} />
        </div>
      ))}
      <div className={css.itemContainer}>
        <DiptychProductV2 products={products} />
      </div>
    </div>
  )
}
