import { env } from "~/env"

import { type UnlikelyMedia, type UnlikelyProduct, type UnlikelyVideo } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { isDarel } from "~/lib/brand"
import { getProcessedLocale } from "~/lib/i18n/utils/get-processed-locale"
import { productMetafields, type COLORS_VALUE } from "~/lib/shopify/constants"
import { getSfMedia } from "~/lib/shopify/queries/get-sf-media"
import { getLocalizedMetafields } from "~/lib/shopify/utils/get-localized-metafields"
import { productHasStockAlert } from "~/lib/shopify/utils/product-utils"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { serializeSfBackInStock } from "~/components/ui/BackInStockPopin/_data/serializer"
import { serializeImages } from "~/components/ui/Image/_data/serializer"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import type { ProductCardSliderProps } from "~/components/ui/ProductCardSlider"
import type { GroupMtc } from "~/components/ui/ProductCardSlider/Footer"
import { serializeSfQuickBuy } from "~/components/ui/ProductCardSlider/Layout/QuickBuy/_data/serializer"
import serializeVariantsSelector from "~/components/ui/ProductHeader/components/ProductDetails/components/ProductVariantsSelector/_data/serializer"
import { serializeVideo } from "~/components/ui/Video/_data/serializer"
import { unlikelyProductToTrackingData } from "~/providers/GTMTrackingProvider/utils/unlikely-product-to-tracking-data"
import { metafieldsToObject } from "~/utils/metafields-to-object"

// TODO: REMOVE THIS ASAP - cannot upgrade to v8 just yet, but isGiftCard is not retrieved by sfCollection products
const GIFT_CARD_IDS = isDarel
  ? {
      DEV: "gid://shopify/Product/14587476083067",
      PROD: "gid://shopify/Product/9616777380181",
    }
  : {
      DEV: "gid://shopify/Product/14681116180867",
      PROD: "gid://shopify/Product/6102548021425",
    }

export async function serializeUnlikelyProductsToProductCardSliders(
  products: Nullish<UnlikelyProduct[]>,
  locale: string
): Promise<ProductCardSliderProps[]> {
  return (
    (
      await Promise.all(products?.map((product) => serializeUnlikelyProductToProductCardSlider(product, locale)) ?? [])
    ).filter(Boolean) ?? []
  )
}

export async function serializeUnlikelyProductToProductCardSlider(
  product: Nullish<UnlikelyProduct>,
  locale: string
): Promise<ProductCardSliderProps | null> {
  if (!product) return null
  const metafields =
    product.metafields && metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))

  const videoCollection = metafields?.videoCollection
    ? (await getSfMedia({ id: metafields.videoCollection, locale })).media
    : product?.media?.find((media) => media?.mediaContentType === "VIDEO")
  return serializeUnlikelyProductToProductCardSync(product, locale, videoCollection)
}

// Sync serializer for product cards to be able to serialize product cards client side without making new requests.
export function serializeUnlikelyProductToProductCardSync(
  product: Nullish<UnlikelyProduct>,
  locale: string,
  videoCollection?: UnlikelyMedia
): ProductCardSliderProps | null {
  if (!product) return null

  const computedLocale = getProcessedLocale(locale)
  const productLink = handleZodSafeParse(linkSchema, product, serializeUnlikelyProductToProductCardSlider.name)
  const { defaultVariant, variants } = serializeVariantsSelector(product, locale)

  if (!product?.title || !productLink || !defaultVariant?.price) return null

  const giftCardIdCompare = env.NEXT_PUBLIC_ENV === "production" ? GIFT_CARD_IDS.PROD : GIFT_CARD_IDS.DEV
  const isGiftCard = product?.id === giftCardIdCompare || product?.isGiftCard || false

  const metafields =
    product.metafields && metafieldsToObject(product.metafields, getLocalizedMetafields(productMetafields, locale))
  const groupMtcAsString = product?.metafields?.[productMetafields["universal"]["groupMtc"]]?.value
  const groupMtc = groupMtcAsString ? (JSON.parse(groupMtcAsString) as GroupMtc) : []
  const isOutOfStock = !isGiftCard && variants.every((variant) => !variant.availableForSale)

  const priceProps = {
    price: defaultVariant?.price,
    compareAtPrice: defaultVariant?.compareAtPrice,
    vpPrice: defaultVariant?.vpPrice,
  }

  const title = product?.title ?? null

  return {
    footer: {
      productId: product?.id,
      primaryColor: (metafields?.primaryColor as keyof typeof COLORS_VALUE) ?? null,
      title,
      groupMtc,
      ...priceProps,
    },
    quickBuyProps: serializeSfQuickBuy(product, locale) ?? [],
    backInStock: (!isGiftCard ? null : serializeSfBackInStock(product, locale)) ?? null,
    isOutOfStock,
    stockAlertEnabled: productHasStockAlert(product),
    ...(product?.handle ? { quickBuy: { handle: product?.handle } } : {}),
    slider: {
      images: serializeImages(product?.images, locale),
      video: videoCollection ? serializeVideo(videoCollection as UnlikelyVideo) : null,
      videoPosition: metafields?.videoPriorityOnCollection === "true" ? 0 : 1,
    },
    link: serializeLink(productLink, computedLocale),
    cardTags: metafields?.cardTags?.split(",") ?? null,
    locale: computedLocale,
    collections: product.collections.map((collection) => collection.handle).filter(Boolean),
    trackingData: unlikelyProductToTrackingData({ product, locale }),
    isGiftCard,
    title,
    ...priceProps,
  }
}
