import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import { Image } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import type { THeroV2Item, TTitleRichtextProps } from "~/components/slices/HeroV2/index.client"

import { sprinkles } from "~/styles/sprinkles.css"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

// todo quand Max m'aura répondu
const SIZES = [{ breakpoint: breakpoints.md, ratio: 28 / 30 }, { ratio: 14 / 60 }]

export type THeroTitleV2 = PropsWithClassName<{
  content: TTitleRichtextProps | TImageDesktopMobileProps
  priority?: boolean
  contentPosition: THeroV2Item["contentPosition"]
}>

export default function HeroTitleV2({ content, priority = false, contentPosition }: THeroTitleV2) {
  const isText = "text" in content
  const isImage = "component" in content && content.component === "image_desktop_mobile"

  if (!content) {
    return null
  }

  if (isText) {
    return (
      <RichText
        className={clsx(css.title({ fontStyleType: content.fontStyleType, contentPosition }))}
        render={content.text}
        disableStyles
      />
    )
  }

  if (isImage) {
    return (
      <>
        {content.assetDesktop && (
          <Image
            {...content.assetDesktop}
            className={clsx(
              css.image,
              css.position({ contentPosition }),
              sprinkles({ display: { mobile: "none", tablet: "flex" } })
            )}
            imageClassName={sprinkles({ height: "100%" })}
            sizesFromBreakpoints={SIZES}
            priority={priority}
          />
        )}
        {content.assetMobile && (
          <Image
            {...content.assetMobile}
            className={clsx(
              css.image,
              css.position({ contentPosition }),
              sprinkles({ display: { mobile: "flex", tablet: "none" } })
            )}
            imageClassName={sprinkles({ height: "100%" })}
            sizesFromBreakpoints={SIZES}
            priority={priority}
          />
        )}
      </>
    )
  }
}
