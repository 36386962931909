import type { UnlikelyProduct } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import { getSelectedOption } from "~/lib/shopify/utils/get-selected-option"
import getVariantAvailabilityFromMarket from "~/lib/shopify/utils/get-variant-availibility-from-market"
import type { QuickBuyButton } from "~/components/ui/ProductCardSlider/Layout/QuickBuy"

export function serializeSfQuickBuy(data: Nullish<UnlikelyProduct>, locale: Nullish<string>): QuickBuyButton[] | null {
  if (!data) return null

  const { variants } = data

  if (!variants?.length) return null

  return variants
    .map((variant) => ({
      id: variant.id,
      title: getSelectedOption(variant, "size"),
      availableForSale: variant.availableForSale && getVariantAvailabilityFromMarket(variant, locale) > 0,
      quantityAvailable: getVariantAvailabilityFromMarket(variant, locale),
    }))
    .filter(({ title }) => Boolean(title))
    .sort((a, b) => Number(a?.title?.replace(/[^0-9]/g, "") ?? 0) - Number(b?.title?.replace(/[^0-9]/g, "") ?? 0))
}
