import clsx from "clsx"

import { FillImage } from "~/components/ui/FillImage"
import { Link } from "~/components/ui/Link"
import Video from "~/components/ui/Video"
import { EmblaSlider } from "~/components/shared/EmblaSlider/index.client"
import type { TNewProductsArray } from "~/components/slices/DiptychCampaignProductV2/index.client"

import { sprinkles } from "~/styles/sprinkles.css"
import { showFrom } from "~/styles/utils/show-hide"
import { breakpoints } from "~/styles/variables/breakpoints"

import * as css from "./styles.css"

const SIZES = [{ breakpoint: breakpoints.md, ratio: 1 / 2 }, { ratio: 1 }]

interface DiptychProductV2Props {
  products: TNewProductsArray[]
  isPriority?: boolean
}

export function DiptychProductV2({ products, isPriority }: DiptychProductV2Props) {
  if (!products) return null

  return (
    <div className={css.DiptychProductV2}>
      <div className={sprinkles({ overflow: "hidden", height: "100%", width: "100%" })}>
        <EmblaSlider
          className={clsx(css.slider)}
          sliderClassName={clsx(css.slides)}
          controlsClassName={clsx(css.navigation)}
          controlButtonClassName={clsx(css.controlButton)}
          buttonStyle="cover"
          buttonType="arrow"
          hideOverflow
          controlsStyle="bottom-right"
          options={{
            align: "start",
            active: products?.length > 1,
            watchDrag: products?.length > 1,
          }}
        >
          {products?.map(({ component, asset, image, link, price, title }, index) => {
            return (
              <div className={css.item} key={index}>
                <div className={sprinkles({ display: "block", width: "100%", height: "100%" })}>
                  <div
                    className={sprinkles({
                      display: "block",
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    })}
                  >
                    {component === "image_desktop_mobile" && asset && (
                      <div className={sprinkles({ width: "100%", height: "100%" })}>
                        {"assetDesktop" in asset && asset.assetDesktop && (
                          <FillImage
                            objectFit="fill"
                            className={clsx(css.image, showFrom("desktop", "block"), showFrom("mobile", "none"))}
                            {...asset.assetDesktop}
                            sizesFromBreakpoints={SIZES}
                            priority={isPriority || index === 0}
                            asPlaceholder
                          />
                        )}
                        {"assetMobile" in asset && asset.assetMobile && (
                          <FillImage
                            className={clsx(css.image, showFrom("mobile", "block"), showFrom("desktop", "none"))}
                            {...asset.assetMobile}
                            sizesFromBreakpoints={SIZES}
                            priority={isPriority || index === 0}
                            asPlaceholder
                          />
                        )}
                      </div>
                    )}
                    {component === "shopify_video" && asset && (
                      <div className={sprinkles({ width: "100%", height: "100%" })}>
                        {"desktop" in asset && asset.desktop && (
                          <Video
                            {...asset.desktop}
                            className={sprinkles({
                              display: { mobile: "none", tablet: "block" },
                              height: "100%",
                            })}
                            fill
                          />
                        )}
                        {"mobile" in asset && asset.mobile && (
                          <Video
                            {...asset.mobile}
                            className={sprinkles({
                              display: { mobile: "block", tablet: "none" },
                              height: "100%",
                            })}
                            fill
                          />
                        )}
                      </div>
                    )}
                    {component === "shopify_product" && (
                      <Link {...link} className={css.link}>
                        <div className={sprinkles({ width: "100%", height: "100%" })}>
                          {image && (
                            <FillImage
                              objectFit="fill"
                              className={clsx(css.image)}
                              sizesFromBreakpoints={SIZES}
                              priority={isPriority || index === 0}
                              asPlaceholder
                              {...image}
                            />
                          )}
                        </div>

                        <div className={css.wrapper}>
                          <div className={css.overlay} />
                          <div className={css.contentTitle}>
                            <p className={css.title}>
                              {title && <span>{title}</span>}
                              {price && <span> - {price}</span>}
                            </p>
                          </div>
                        </div>
                      </Link>
                    )}
                    <div className={css.wrapper}>
                      <div className={css.overlay} />
                      <div className={css.contentTitle}>
                        <p className={css.title}>
                          {title && <span>{title}</span>}
                          {price && <span> - {price}</span>}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </EmblaSlider>
      </div>
    </div>
  )
}
