"use client"

import { useRef } from "react"
import clsx from "clsx"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import type { TImageDesktopMobileProps } from "~/lib/storyblok/schemas/library/components/image_desktop_mobile"
import type { SbComponentShopifyProduct } from "~/lib/storyblok/schemas/library/components/shopify_product"
import type { TShopifyVideo } from "~/lib/storyblok/schemas/library/components/shopify_video"
import ImageOrVideo from "~/components/ui/ImageOrVideo"
import { Link } from "~/components/ui/Link"
import type { TLinkLabel } from "~/components/ui/Link/_data/serializer"
import Icon from "~/components/abstracts/Icon"

import { sprinkles } from "~/styles/sprinkles.css"
import { text } from "~/styles/variants"

import * as css from "./styles.css"

export type TDiptychEditoSecondVisualV2 = PropsWithClassName<{
  component: "diptych_edito_second_visual_v2"
  background: (TShopifyVideo | TImageDesktopMobileProps)[]
  productName: string | null
  link: TLinkLabel | null
  locale?: string
  product?: SbComponentShopifyProduct | null
  price?: string | null
  priority?: boolean
}>

export default function DiptychEditoSecondVisualV2({
  className,
  background,
  link,
  productName,
  price,
  priority = false,
}: TDiptychEditoSecondVisualV2) {
  const containerRef = useRef<HTMLDivElement>(null)
  const [{ nextSlide, prevSlide }, setSliderState] = useSliderState()

  if (background.length === 0) return null

  const manyBackgrounds = background.length > 1

  return (
    <section className={clsx(className, css.DiptychEditoSecondVisualV2)}>
      <div className={sprinkles({ overflow: "hidden" })}>
        <Slider
          setSliderState={setSliderState}
          customSliderRef={containerRef}
          snap
          dragProps={{ enable: false }}
          infinite
          maxSlideIndexChange={1}
        >
          {background.map((item, index) => {
            return (
              <div className={css.item} key={`slider-${index}`}>
                <Link
                  className={sprinkles({
                    display: "block",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  })}
                  {...link}
                >
                  <ImageOrVideo
                    className={sprinkles({ height: "100%", width: "100%" })}
                    background={item}
                    priority={priority}
                  />
                </Link>
              </div>
            )
          })}
        </Slider>
        <div className={css.content}>
          {productName && (
            <Link {...link}>
              <h3 className={text({ design: "text-large-regular", color: "ui-900" })}>
                {productName} {price && `- ${price}`}
              </h3>
            </Link>
          )}
          {manyBackgrounds && (
            <div className={css.controls}>
              <button className={clsx(css.button)} onClick={prevSlide}>
                <Icon name="Arrow" height={16} width={16} />
              </button>
              <button className={clsx(css.button)} onClick={nextSlide}>
                <Icon direction="right" height={16} width={16} name="Arrow" />
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
